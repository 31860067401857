<!-- eslint-disable @typescript-eslint/no-unsafe-assignment -->
<script setup lang="ts">
import {
  computed, defineAsyncComponent, ref, watchPostEffect,
} from 'vue';
import { useData, useRoute } from 'vitepress';
import { useWindowScroll } from '@vueuse/core';

const ViewHome = defineAsyncComponent(() => import('@/layouts/Home/ViewHome.vue'));
const TagList = defineAsyncComponent(() => import('@/layouts/Tag/TagList.vue'));
const ViewEventDetail = defineAsyncComponent(() => import('@/layouts/Event/ViewEventDetail.vue'));
const ViewUpdatesRedirect = defineAsyncComponent(() => import('@/layouts/Updates/ViewUpdatesRedirect.vue'));
const AuthorDetail = defineAsyncComponent(() => import('@/layouts/Author/Detail.vue'));
const ViewArchive = defineAsyncComponent(() => import('@/layouts/Archive/ViewArchive.vue'));
const ViewPageDetail = defineAsyncComponent(() => import('@/layouts/Page/ViewPageDetail.vue'));
const CourseList = defineAsyncComponent(() => import('@/layouts/Course/CourseList.vue'));
const CourseDetail = defineAsyncComponent(() => import('@/layouts/Course/CourseDetail.vue'));
const ViewErrors = defineAsyncComponent(() => import('UiKit/components/Views/ViewErrorNotFound404.vue'));

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const BaseToaster = defineAsyncComponent(() => import(
  'UiKit/components/VToaster/VToaster.vue'
));

const { page, frontmatter } = useData();
const route = useRoute();
const { y } = useWindowScroll();


const isFixed = ref(false);

watchPostEffect(() => {
  if (y.value > 0) {
    isFixed.value = true;
  } else {
    isFixed.value = false;
  }
});
</script>


<template>
  <div class="AppLayoutDefault app-layout-default">
    <main class="app-layout-default__main is--markdown">
      <ViewErrors
        v-if="page.isNotFound"
      />
      <ViewHome v-else-if="frontmatter.layout === 'home'" />
      <ViewPageDetail
        v-else-if="frontmatter.layout === 'page-detail'"
      />
      <ViewEventDetail
        v-else-if="frontmatter.layout === 'event-detail'"
      />
      <TagList
        v-else-if="frontmatter.layout === 'tag-list'"
      />
      <ViewUpdatesRedirect
        v-else-if="frontmatter.layout === 'news-updates'"
      />
      <AuthorDetail
        v-else-if="frontmatter.layout === 'author-detail'"
      />
      <CourseList
        v-else-if="frontmatter.layout === 'course-list'"
      />
      <CourseDetail
        v-else-if="frontmatter.layout === 'course-detail'"
      />
      <ViewArchive
        v-else-if="frontmatter.layout === 'archives'"
      />
      <div
        v-else
        class="is--content is--container is--page"
      >
        <Content />
        <!-- if we use somewhere Content it is mandatory to wrap it in container with class 'is--markdown'-->
      </div>
    </main>
  </div>
</template>


<style lang="scss">

.app-layout-default {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 100;
    height: $header-height;
    transition: all .12s ease-in-out;

    &.fixed {
      position: fixed;
      top: 0;
      background: $white;
      box-shadow: $box-shadow-small;
    }
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    z-index: 1;
    background: $white;
  }

  .is--content {
    width: 100%;
  }

  &__footer {
    width: 100%;
    z-index: 1;
    margin-top: 0 !important;
  }
}
</style>
